<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.279 18.427"
    aria-hidden="true"
  >
    <path
      id="Path_163"
      data-name="Path 163"
      d="M19.279,19.133V12c0-3.82-2.039-5.6-4.757-5.6A4.1,4.1,0,0,0,10.8,8.462V6.7H6.664c.055,1.167,0,12.433,0,12.433H10.8V12.19a2.816,2.816,0,0,1,.136-1.007,2.261,2.261,0,0,1,2.119-1.512c1.5,0,2.094,1.14,2.094,2.811v6.652h4.132ZM2.31,5a2.155,2.155,0,1,0,.028-4.3A2.154,2.154,0,1,0,2.285,5Zm2.066,14.13V6.7H.245V19.133Z"
      transform="translate(0 -0.707)"
      fill="currentColor"
      class="transition-colors duration-150"
    />
  </svg>
</template>
